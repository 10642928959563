@import "src/styles/variables", 'src/styles/mixins';

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
}

.ng-lazyloading {
  //filter: blur(50px);
  backdrop-filter: blur(50px);
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.marker {
  position: absolute;
  .closed-icon {
    cursor: pointer;
    width: 90px;
    height: auto;
    transition: 200ms transform ease;
    &:hover {
      transform: rotate(-180deg);
      border: 5px rgba(255, 255, 255, 0.7) solid;
      border-radius: 50%;
    }
    @include lg {
      width: 65px;
    }
  }

  &.active {
    .closed-wrapper{
      display: none !important;
    }
  }

  .closed-text {
    min-width: 200px;
    background: #fff;
    padding: 14px 13.8px 13px 16.2px;
    position: relative;
    left: -43px;
    top: 6px;
    @include lg {
      padding: 8px 10px 8px 10px;
      min-width: 160px;
      left: -32px;
    }
    h5 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      @include lg {
        font-size: 16px;
      }
    }
  }

  .active-body-image-wrapper {
    width: 493px;
    height: 493px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .active-background-image {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .active-image {
      width: 363px;
      height: 363px;
      position: absolute;
      object-fit: cover;
      border-radius: 50%;
      @include lg {
        width: 126px;
        height: 126px;
      }
    }
    @include lg {
      width: 150px;
      height: 150px;
    }
  }

  &.active {
    .active-body {
      display: flex;
      top: -100px;
      left: calc(50% - 150px);
      transform: translateX(-50%);
    }
  }

  .active-body {
    align-items: center;
    position: relative;
    display: none;
    z-index: 1;
  }
  .content {
    position: relative;
    right: 20%;
    top: 80px;
    display: flex;
    flex-direction: column;
    align-items: start;
    @include lg {
      top: 60px;
    }
  }

  .button {
    background: var(--session-color);
    padding: 15px 14.8px 15px 19px;
    cursor: pointer;
    color: white;
    @include lg {
      padding: 8px 15px;
    }
    span {
      margin-right: 5px;
    }
  }

  .title-wrapper {
    padding: 20px;
    background: white;
    min-width: 160px;
    @include lg {
      padding: 13px 10px 10px 20px;
    }
    h4 {
      @include lg {
        font-size: 14px;
        padding-bottom: 10px;
        font-weight: 500;
      }
    }
  }
  .tiny {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: #333333;
    margin-bottom: 0;
    @include lg {
      font-size: 12px;
    }
  }
}

.navigation-hotspot {
  position: relative;
  display: flex;
  cursor: pointer;
  &.has-icon {
    flex-direction: column-reverse;
    .text-wrapper {
      margin-left: 0;
    }
    img {
      width: 80px;
      transform: rotateX(55deg);
    }
  }
  .text-wrapper {
    background: white;
    padding: 10px 15px;
    margin-left: 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    @include lg {
      padding: 6px 15px;
    }
  }

  .icon {
    width: 40px;
    background: #f07d1a;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 34px;
    padding-bottom: 5px;
    @include lg {
      font-size: 26px;
      width: 32px;
      height: 32px;
    }
  }
}

h1.white {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 70px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--text-color);
}

h2.white {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 46px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--text-color);
}

h1 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 1.5;
  }
}

h1.mobile2 {
  @media screen and (max-width: 768px) {
    line-height: 1.6;
    color: var(--text-color);;
  }
}

h2 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 1.5;
    color: var(--text-color);
  }
}

h2.white2 {
  color: var(--text-color);
}

h2.mobile2 {
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 1.5;
    color: var(--text-color);
  }
}

h3,
.h3{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 35px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 1.5;
  }
}

h3.mobile2 {
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 1.36;
    color: var(--text-color);
  }
}

h4,
.h4{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    //color: var(--brand-colours-white);
    font-weight: 300;
  }
}

h4.mobile2 {
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 1.5;
    color: var(--text-color);
  }
}


h5,
.h5 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    line-height: 1.7;
  }
}

h5.mobile2,
.h5.mobile2 {
  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 1.7;
    color: var(--text-color);
  }
}

h5.mobile2-2 {
  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 1.7;
    color: var(--text-color);
  }
}

h6,
.h6,
.hero-nav {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 16px;
    letter-spacing: 2.29px;
  }
}

.hero-nav {
  font-weight: 400;
}

h6.mobile2,
.h6.mobile2 {
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.38;
    color: var(--text-color);
  }
}

.QuotesBlockquote {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 1.4;
    color: var(--text-color);
  }
}
.Blockquote {
  @media screen and (max-width: 768px) {
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 25px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-color);
  }
}

.Blockquote.mobil2 {
  @media screen and (max-width: 768px) {
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-color);
  }
}

.showing-matches-for {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: 1.48px;
  color: #141314;
  span {
    font-weight: bold;
  }
}

.Blockquote-Small {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color);
}

.Symbol-OrganizerGroup-Title {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: var(--text-color);
}

h5.search-result-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

p,
.paragraph{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--text-color);
}

p.p2,
.paragraph.p2,
.paragraph.p2 p{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    color: var(--text-color);
  }
}

p.lead,
.paragraph.lead,
.paragraph.lead p{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 1.5;
  }
}

u {
  text-decoration: none;
  border-bottom: 2px solid var(--session-color);
}

p.small,
.paragraph.small,
.paragraph.small p{
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.buttonsCTA {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color);
  text-decoration: none!important;
  cursor: pointer;
}

.buttonsCTA2 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--brand-colours-charcoal-grey-2);
}

.buttonsCTA-small {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
  text-decoration: none!important;
}

.uppercase.white13 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--brand-colours-white);
}

.uppercase {
  font-family: 'IBM PLEX SANS', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--text-color);
}

.uppercas.white12 {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  text-align: center;
  color: var(--brand-colours-white);
}

.login-register {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brand-colours-white);
}

.white-color  {
  color: var(--brand-colours-white);
}

a:hover {
  color: initial;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

a {
  color: var(--text-color);
}

.popper {
  display: inline-block;
  background: white !important;
  border-radius: 2px;
  padding: 10px;
  -webkit-box-shadow: -12px 12px 12px -7px rgba(0,0,0,0.26);
  box-shadow: -12px 12px 12px -7px rgba(0,0,0,0.26);
  z-index: 99999;
  &[x-placement^="bottom"] {
    margin-top: 15px;
  }
  &[x-placement^="top"] {
    margin-bottom: 15px;
  }
  &__arrow {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    border: {
      style: solid;
      width: 10px;
      color: transparent;
    }
  }
  &[x-placement^="top"] &__arrow {
    border-bottom-width: 0;
    border-top-color: white;
    bottom: -9px;
  }
  &[x-placement^="bottom"] &__arrow {
    border-top-width: 0;
    border-bottom-color: #eee;
    top: -10px;
  }
}

.underline {
  border-bottom: solid 2px var(--session-color);
}

//.text-item{
//  display: block;
//  padding-top: 50px;
//  padding-bottom: 50px;
//  @media screen and (max-width: 768px) {
//    padding-top: 12.5px;
//    padding-bottom: 20px;
//  }
//  &:first-child {
//    padding-top: 68px;
//    @media screen and (max-width: 768px) {
//      padding-top: 25px;
//    }
//  }
//  &:last-child {
//    padding-bottom: 100px;
//    @media screen and (max-width: 768px) {
//      padding-bottom: 40px;;
//    }
//  }
//}
//
//.text-item-column {
//  @media screen and (max-width: 768px) {
//    padding-top: 0px;
//    padding-bottom: 0px;
//  }
//
//  &:first-child {
//    @media screen and (max-width: 768px) {
//      padding-top: 25px;
//    }
//  }
//
//  &:last-child {
//    @media screen and (max-width: 768px) {
//      padding-bottom: 40px;;
//    }
//  }
//}

.accordion-mobile {
  .accordion-button:not(.collapsed),
  .accordion-button {
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--black);
    background: none;
    box-shadow: none;
    border-bottom: solid 1px var(--black);
    &:focus {
      box-shadow: none;
    }
    &:after {
      width: 1rem;
      height: 1rem;
      background-size: 1rem;
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var(--black)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }

  .accordion-button:not(.collapsed) {
    border-bottom: solid 1px var(--black);
    &:after {
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var(--black)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }

  .accordion-body {
    margin-top: 29px;
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black);
    margin-bottom: 10px;
  }

  .accordion-item {
    border: none;
    margin-bottom: 30px;
  }
}

.form-group,
.form-check {
  label{
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    text-decoration: none!important;
    padding-bottom: 8px;
    position: relative;
    margin-top: 54px;
    &.selected:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 39px;
      left: 0;
      border-bottom: 2px solid var(--session-color);
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  textarea {
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--black);
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 27px 0;
    margin-top: 6px;
    width: 100%;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      line-height: 1.5;
    }
    &:focus{
      box-shadow: none;
      border: none;
      border-bottom: solid 1px var(--brand-colours-charcoal-grey);
    }
  }
  .form-check-input{
    font-family: 'IBM PLEX SANS', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: var(--black);
    border-radius: 0;
    &:checked {
      background-color: var(--session-color);
      border-color: var(--session-color);
    }
    &:focus {
      box-shadow: none;
    }
  }

  .form-check-label {
    margin-top: 0;
    margin-left: 20px;
    width: 85%;
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }
  .alert {
    /* font-family: IBMPlexSans;
     font-size: 20px;
     font-weight: 300;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.2;
     letter-spacing: normal;
     color: var(--brand-colours-pink-coffee);
     padding: 0;
     background: none;
     border: none;*/
    padding: 0;
    margin: 0;
    color: var(--brand-colours-pink-coffee)!important;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: var(--brand-colours-pink-coffee);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: var(--brand-colours-pink-coffee);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: var(--brand-colours-pink-coffee);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: var(--brand-colours-pink-coffee);
    }
    &::placeholder {
      color: var(--brand-colours-pink-coffee);
    }
  }
}
.form-check {
  margin-top: 32px;
  margin-bottom: 46px;
  .alert {
    padding: 0 0 8px 0;
    margin-left: 20px;
    border: none;
    margin-bottom: 0;
    color: var(--brand-colours-pink-coffee);
  }
}

a.pp {
  display: block;
  text-decoration: underline;
  font-weight: 800 ;
}

.form-button-submit {
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--brand-colours-charcoal-grey-2);
  border: solid 2px var(--brand-colours-charcoal-grey-2); ;
  padding: 20px 65px;
}

.social-title {
  border-right: solid 0.5px rgba(42,42,41,0.4);
  line-height: 26px;
  max-width: calc(100% - 39px);
  margin-right: 19px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.px-6 {
  padding-left:  (1rem * 1.25);
  padding-right:  (1rem * 1.25);
}

.primary-sns-btn {
  padding: 14px 0;
  border:solid 2px var(--brand-colours-charcoal-grey-2);
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brand-colours-charcoal-grey-2);
  text-decoration: none!important;
  cursor: pointer;
}

.primary-sns-selected-btn {
  padding: 14px 0;
  font-family: 'IBM PLEX SANS', sans-serif;
  font-size: 18px;
  background-color: #efefef;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brand-colours-charcoal-grey-2);
  text-decoration: none!important;
}

.sns-app-store {
  background: url('../assets/svg/app-store.svg') center center no-repeat;
}
.sns-google-play {
  background: url('../assets/svg/google-play.svg') center center no-repeat;
}

.sns-app-store,
.sns-google-play {
  width: 120px;
  height: 36px;
  display: inline-block;
  background-size: cover;
}
.modal {
  z-index: 8055;
}

.close-btn-modal {
  width: 40px;
  height: 40px;
  background: url('/assets/svg/x.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 99;
  cursor: pointer;
  right: 17px;
  top:10px;
}

@media only screen and (device-width:768px) {
  .ipad-padding {
    padding-left: 5px;
  }
}

.channel-border {
  padding: 0 10px 0 11px;
  border: 2px solid var(--session-color);
  border-radius: 4px;
  display: inline-block;
  color: var(--session-color) !important;
  margin-left: 30px;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  min-height: calc(100vh - 900px);
  justify-content: center;
  align-items: center;
}
