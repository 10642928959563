@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'variables';

// Small devices
@mixin sm {
  @media (max-width: 576px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: 768px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: 992px) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin oneSideContainerFluid( $grElemPX: 0px ) {
  @include media-breakpoint-up(sm) {
    width: calc( 576px + ((100% - 576px) / 2) - #{$grElemPX});
  }

  @include media-breakpoint-up(md) {
    width: calc( 768px + ((100% - 768px) / 2) - #{$grElemPX});
  }

  @include media-breakpoint-up(lg) {
    width: calc( 992px + ((100% - 992px) / 2) - #{$grElemPX});
  }

  @include media-breakpoint-up(xl) {
    width: calc( 1200px + ((100% - 1200px) / 2) - #{$grElemPX});
  }
}

@mixin lineClamp($linesDesktop, $linesMobile) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $linesDesktop;
  line-clamp: $linesDesktop;
  -webkit-box-orient: vertical;
  @include md{
    -webkit-line-clamp: $linesMobile;
    line-clamp: $linesMobile;
  }
}

