/* You can add global styles to this file, and also import other style files */


@font-face {
  font-family: 'IBM PLEX SANS';
  src: url(/assets/IBM_Plex_Sans/IBMPlexSans-Light.ttf) format("truetype");
  font-weight:300;
  font-display: swap;
}

@font-face {
  font-family: 'IBM PLEX SANS';
  src: url(/assets/IBM_Plex_Sans/IBMPlexSans-Regular.ttf) format("truetype");
  font-weight:400;
  font-display: swap;
}


@font-face {
  font-family: 'IBM PLEX SANS';
  src: url(/assets/IBM_Plex_Sans/IBMPlexSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
}



@import './defaults','./variables', './global', './mixins';
