$--brand-colours-green-chamaleon: --brand-colours-green-chamaleon;
$--brand-colours-red-pop-art: --brand-colours-red-pop-art;
$--brand-colours-turquoise-astronaut: --brand-colours-turquoise-astronaut;
$--brand-colours-charcoal-grey: --brand-colours-charcoal-grey;
$--charcoal-grey-50: --charcoal-grey-50;
$--brand-colours-yellow-marilyn: --brand-colours-yellow-marilyn;
$--brand-colours-charcoal-grey-2: --brand-colours-charcoal-grey-2;
$--black: --black;
$--brand-colours-white: --brand-colours-white;
$--brand-colours-orange-da-vinci: --brand-colours-orange-da-vinci;
$--brand-colours-pink-coffee: --brand-colours-pink-coffee;

$darkgrey: #141515;

:root {
  --brand-colours-green-chamaleon: #c4d82e;
  --brand-colours-red-pop-art: #ee352a;
  --brand-colours-turquoise-astronaut: #23b7c5;
  --brand-colours-charcoal-grey: #2a2a29;
  --charcoal-grey-50: rgba(42, 42, 41, 0.5);
  --brand-colours-yellow-marilyn: #f7c940;
  --brand-colours-charcoal-grey-2: #222223;
  --black: #000000;
  --brand-dark-grey:  #101010;
  --brand-colours-white: #ffffff;
  --brand-colours-orange-da-vinci: #f5822b;
  --brand-colours-pink-coffee: #ea1d89;
  --session-color: #fff;
  --brand-colours-charcoal-grey-2-opacity: rgba(34, 34, 35, 0.1);
  --black-5-opacity: rgba(0, 0, 0, 0.5);
}
